import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Button, Card, Col, Container, Input, Label, Row, TabContent, TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from "classnames"

import { map } from "lodash"
import axios from "axios"
import { API_URL } from "helpers/app_url"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailToolbar from "./email-toolbar"
import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import moment from "moment";
class EmailInbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      activeTab: "1",
      resultArray: [],
      isLoading: false
    }
    this.togglemodal.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

    componentDidMount() {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        const userId = localStorage.getItem('userId');
        this.setState({ isLoading: true });
          axios.get(`${API_URL}Gmail/SyncGmails/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
          })
          .then(response => {
              if(response.data==='Success'){
              
                axios.get(`${API_URL}Gmail/GetSyncGmailsFromLocal/${userId}`, {
                  headers: { Authorization: `Bearer ${token}` }
              })
              .then(secondResponse => {
                  console.log('Response from the second API:', secondResponse.data);
                  this.setState({
                    inboxmails: secondResponse.data,
                    isLoading: false,
                  });
              })
              .catch(error => {
                  console.error('Error fetching data from second API:', error);
                  this.setState({ isLoading: false });
              });
              }
        
          })
          .catch(error => {
              console.error('Error fetching data from API:', error);
              this.setState({ isLoading: false });
          });

    }

  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  
  handleSubmit(value) {
    this.setState({ isLoading: true });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      name: value.name,
      from:value.from,
      to: value.to,
      subject:value.subject,
      body:value.body,
      agentId:value.agentId
    }
    axios.post(`${API_URL}Gmail`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log("API Response:", response.data);
      this.setState({ isLoading: false });
      this.togglemodal();
    })
    .catch(error => {
      console.error("API Error:", error);
      this.setState({ isLoading: false });
    });
  }
  render() {

    //meta title
    document.title = "Email | Realty Space";

    const { inboxmails, isLoading} = this.state;
    const initialValues = ""

    const validationSchema = Yup.object().shape({
      to: Yup.string().email('Invalid email format').required('Email is required'),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required'),
    });

    
    return (
      <React.Fragment>
         {isLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Email" breadcrumbItem="Inbox" />

            <Row>
              <Col xs="12">
                {/* Render Email SideBar */}
               <Card className="email-leftbar">
                  <Button
                    type="button"
                    color="danger"
                    onClick={this.togglemodal}
                    block
                  >
                    Compose
                  </Button>
                  <div className="mail-list mt-4">
                    <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1")
                          }}
                        >
                          <i className="mdi mdi-email-outline me-2"></i> Inbox{" "}
                          <span className="ml-1 float-end">(18)</span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2")
                          }}
                        >
                          <i className="mdi mdi-star-outline me-2"></i>Starred
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTab("3")
                          }}
                        >
                          <i className="mdi mdi-diamond-stone me-2"></i>Important
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggleTab("4")
                          }}
                        >
                          <i className="mdi mdi-file-outline me-2"></i>Draft
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggleTab("5")
                          }}
                        >
                          <i className="mdi mdi-email-check-outline me-2"></i>Sent Mail
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggleTab("6")
                          }}
                        >
                          <i className="mdi mdi-trash-can-outline me-2"></i>Trash
                        </NavLink>
                      </NavItem>

                    </Nav>

                  </div>
                </Card> 

                <Modal
                  isOpen={this.state.modal}
                  role="dialog"
                  autoFocus={true}
                  centered={true}
                  className="exampleModal"
                  tabIndex="-1"
                  toggle={this.togglemodal}
                >
                  <div className="modal-content">
                    <ModalHeader toggle={this.togglemodal}>New Message</ModalHeader>
                    <ModalBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        name:'Sudhir',
                        from:'sudhirsingh.info@gmail.com',
                        to: '',
                        subject: '',
                       
                        agentId: parseInt(localStorage.getItem('userId'))
                      }}
                      validationSchema={Yup.object().shape({
                        to: Yup.string().email('Invalid email format').required('Email is required'),
                        subject: Yup.string().required('Subject is required'),
                       
                      })}
                      onSubmit={this.handleSubmit}
                  >
                      {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                  <div className="mb-3">
                    <Field
                      name="to"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="To"
                    />
                    <ErrorMessage name="to" component="div" className="text-danger" />
                  </div>

                  <div className="mb-3">
                    <Field
                      name="subject"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                    <ErrorMessage name="subject" component="div" className="text-danger" />
                  </div>

                  <div className="mb-3">
                    <label>Message</label>
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('body', data);
                      }}

                    />
                    <ErrorMessage name="body" component="div" className="text-danger" />
                  </div>

                  <ModalFooter>
                    <Button type="button" color="secondary" onClick={this.togglemodal}>
                      Close
                    </Button>
                    <Button type="submit" color="primary">
                      Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
                    </ModalBody>
                    
                  </div>
                </Modal>
                <div className="email-rightbar mb-3">
                  <Card>
                    {/* Render Email Top Tool Bar */}
                    <EmailToolbar />
                    <TabContent
                      activeTab={this.state.activeTab}
                    >
                      <TabPane tabId="1">
                      <ul className="message-list">
                      {map(inboxmails, (inbox, key) => {
                        const createdOnDate = moment(inbox.createdOn);
                        const isToday = createdOnDate.isSame(moment(), 'day');

                        return (
                          <li key={key} className={inbox.read ? "" : ""}>
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input type="checkbox" id={inbox.id} />
                                <Label htmlFor={inbox.id} className="toggle" />
                              </div>
                              <Link to={`/email-read/${inbox.id}`} className="title">
                                {inbox.from}
                              </Link>
                            </div>
                            <div className="col-mail col-mail-2">
                              <div dangerouslySetInnerHTML={{ __html: inbox.subject }}></div>
                              <div className="date">
                                {isToday
                                  ? createdOnDate.format('h:mm A') 
                                  : createdOnDate.format('MMM DD')}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                      </TabPane>
                    </TabContent>
                  </Card>
                  <Row>
                    <Col xs="7">Showing 1 - 20 of 20</Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EmailInbox.propTypes = {
  activeTab: PropTypes.string,
 
}

const mapStateToProps = ({ Mails }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailInbox))