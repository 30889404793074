import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
//import { withTranslation } from "react-i18next"
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addLeadEmailLog, getLeadEmailLog } from "../../../store/actions"
import Moment from 'react-moment';
import { isEmpty, map } from "lodash";
import {
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import SaveButton from "components/buttons/save";
class EmailLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }
    this.handSubmit = this.handSubmit.bind(this)
  } 
  handSubmit(value) {
    this.props.addLeadEmailLog(value, this.props.history)
  }
  componentDidMount() {
    const { match:{params}, onGetLeadEmailLog} = this.props;
    onGetLeadEmailLog(params.id);
} 
  render() {
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { emaillogs } = this.props;
    return (
      <React.Fragment>
        <div className="mb-5 h4 card-title">Email Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          remarks: (this.state && this.state.remarks) || "",
                          actionType: (this.state && this.state.actionType) || "",
                          addedDate: new Date(),
                          refId: this.props.match.params.id,
                        
                        }}
                        validationSchema={Yup.object().shape({
                          actionType: Yup.string().required(
                            "Please Select Action"
                          ),
                          remarks: Yup.string().max(500, 'This filed contains only 500 characters only').required("This is Required")
                        })}

                        onSubmit={this.handSubmit}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent"  onChange={handleChange}  />
                                          Email Sent
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Email Received"  onChange={handleChange} />
                                          Email Received
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType3" value="Email Bounced"  onChange={handleChange}  />
                                          Email Bounced
                                        </label>
                                        <ErrorMessage
                                              name="actionType"
                                              component="div"
                                              className="text-danger"
                                            />
                                        
                                      </div>

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.addedDate && touched.addedDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.addedDate}
                                              onChange={(value) => setFieldValue('addedDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="addedDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="remarks">
                                          {this.props.t("Add Message")}
                                        </Label>
                                        <textarea
                                          name="remarks"
                                          onChange={handleChange}
                                          type="textarea"
                                         
                                          className="form-control"
                                         
                                          id="remarks"

                                        />
                                        <ErrorMessage
                                              name="remarks"
                                              component="div"
                                              className="text-danger"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                          {map(emaillogs, (emailLog, emailkey) => (

                            <li className="event-list eventlist" key={"_email_" + emailkey}>
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle font-size-18" />
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <h5 className="font-size-14">
                                    <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                    <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                  </h5>
                                </div>
                                <div className="flex-grow-1">
                                  <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                </div>
                              </div>
                              <div className="exNote">{emailLog.activityRemarks}</div>
                            </li>
                          ))}

</ul>
                </React.Fragment>
    )
  }
}
EmailLog.propTypes = {
  history:PropTypes.object,
  t: PropTypes.any,
  match:PropTypes.object,
  emaillogs:PropTypes.array,
  addLeadEmailLog:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func
}
const mapStateToProps = ({ leads }) => (
  { 
    emaillogs:leads.emaillogs,
  })
  const mapDispatchToProps = dispatch => ({
   
    addLeadEmailLog:(data) => dispatch(addLeadEmailLog(data)),
    onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
   
  });
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailLog)))

