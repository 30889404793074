import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import { API_URL } from "helpers/app_url";
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GeneralSettingMenu from "../menu/general-setting-menu";
import RsLink from "components/buttons/rsLink";
class GenralCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isGmailActive : false,
            isCalActive : false,
            isLoader: true
           
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleCalanderClick = this.handleCalanderClick.bind(this);
    }
    
    handleClick() {
        this.setState({ isLoading: true });
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        const userId = localStorage.getItem('userId');
        const url = `${API_URL}Gmail/Auth/${userId}`;
        axios.get(url)
            .then(response => {
                window.location.href = response.data;
                this.setState({ isLoading: false });
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || "Failed to save settings.";
                toast.error(errorMessage);
                this.setState({ isLoading: false });
            });


       
    }
    componentDidMount() {
       
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        const userId = localStorage.getItem('userId');
        const checkGmailUrl = `${API_URL}Gmail/GetGmailEventsFromAgent/${userId}`;
        axios.get(checkGmailUrl,{
            headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                this.setState({
                    isGmailActive: response.data,
                    isLoader: false
                  });
               
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || "Failed to save settings.";
                toast.error(errorMessage);
                this.setState({ isLoader: false });
            });

        
            const checkCalUrl = `${API_URL}Google/GetGoogleEventsFromAgent/${userId}`;
            axios.get(checkCalUrl,{
                headers: { Authorization: `Bearer ${token}` }
                })
                .then(response => {
                    this.setState({
                        isCalActive: response.data,
                        isLoader: false
                      });
                   
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || "Failed to save settings.";
                    toast.error(errorMessage);
                    this.setState({ isLoader: false });
                });
    }
    handleCalanderClick() {
        this.setState({ iscalLoading: true });
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        const userId = localStorage.getItem("userId");
        const url = `${API_URL}Google/Auth/${userId}`;
        axios.get(url,{
            headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                window.location.href = response.data;
                this.setState({ iscalLoading: false });
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || "Failed to save settings.";
                toast.error(errorMessage);
                this.setState({ iscalLoading: false });
            });

    }
    render() {
        const { isGmailActive, isCalActive, isLoader } = this.state;
        return (
            <React.Fragment>
                {isLoader ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <div className="page-content">
                    <MetaTags>
                        <title>General Setting Security | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}
                                    breadcrumbItem={this.props.t("General Settings")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>
                            <Col xl="9">
                                <GeneralSettingMenu />
                                <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12" className="text-center">
                                                        {isGmailActive ? (
                                                            <RsLink 
                                                                iconClass="bx bxl-google"
                                                                className="btn-google"
                                                            
                                                                isLoading={this.state.isLoading}
                                                            >
                                                            {this.state.isLoading ? 'Please wait...' : 'Disconnect Gmail'}
                                                            </RsLink>
                                                        ):(
                                                            <RsLink 
                                                            iconClass="bx bxl-google"
                                                            className="btn-google"
                                                            onClick={this.handleClick}
                                                            isLoading={this.state.isLoading}
                                                        >
                                                            {this.state.isLoading ? 'Please wait...' : 'Connect Gmail'}
                                                        </RsLink>    
                                                        )}
                                                    {" "}
                                                    {/* {isCalActive ? (
                                                <RsLink 
                                                    iconClass="bx bxl-google"
                                                     className="btn-google"
                                                  
                                                    isLoading={this.state.iscalLoading}
                                                >
                                                    {this.state.iscalLoading ? 'Please wait...' : 'Disconnect Google Calander'}
                                                </RsLink>
                                                    ):( */}
                                                        <RsLink 
                                                        iconClass="bx bxl-google"
                                                         className="btn-google"
                                                        onClick={this.handleCalanderClick}
                                                        isLoading={this.state.iscalLoading}
                                                    >
                                                        {this.state.iscalLoading ? 'Please wait...' : 'Connect Google Calander'}
                                                    </RsLink>
                                                {/* )} */}
                                </Col>
                                </Row>
                                </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
GenralCalendar.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
}
const mapStateToProps = () => (
    {
    })
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GenralCalendar))
