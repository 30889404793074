import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';
import { isEmpty, map } from "lodash";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import {
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap"
import { withTranslation } from "react-i18next";
import { addLeadNoteTab, getAppLeadTab, getNoteLeadTab } from "../../../store/leads/actions"
import SaveButton from "components/buttons/save";
class LeedNote extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }
    this.handleLeadNoteSubmit = this.handleLeadNoteSubmit.bind(this)
  }  
  handleLeadNoteSubmit(value) {
    this.props.addLeadNoteTab(value, this.props.history)
   }
   componentDidMount() {
    const { match:{params}, onGetNoteLead } = this.props;
    
    onGetNoteLead(params.id);
  }
  render() {
    const { leadNotes } = this.props;
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="mb-5 h4 card-title">Notes</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          addNote: (this.state && this.state.addNote) || "",
                          logDate: new Date(),
                          refId:this.props.match.params.id
                        
                        }}
                        validationSchema={Yup.object().shape({
                            addNote: Yup.string().max(500, 'This filed contains only 500 characters only').required("This is Required")
                        })}

                        onSubmit={this.handleLeadNoteSubmit}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="addNote">
                                          {this.props.t("Add Note*")}
                                        </Label>
                                        <textarea
                                          name="addNote"
                                          onChange={handleChange}
                                          // placeholder="only 500 Characters are allowed"
                                          type="textarea"
                                          className={
                                            "form-control" +
                                            (errors.addNote && touched.addNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                          id="addNote"
                                        />
                                         <ErrorMessage
                                              name="addNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><SaveButton /></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
                </React.Fragment>
    )
  }
}
LeedNote.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
match: PropTypes.object,
  addLeadNoteTab:PropTypes.func,
  onGetNoteLead: PropTypes.func,
  leadNotes:PropTypes.array

}

const mapStateToProps = ({ leads }) => (
  {
    leadNotes: leads.leadNotes,
    
  })
const mapDispatchToProps = dispatch => ({
  addLeadNoteTab: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: leadId => dispatch(getNoteLeadTab(leadId)),
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LeedNote)))
